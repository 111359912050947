import React, { useState } from "react"
import Highlight, { defaultProps } from "prism-react-renderer"
import tw, { css} from 'twin.macro'


export default (props) => {
    const className = props.children.props.className || 'language-text'

    const language = className.replace("language-", "")
    const matches = className.match(/language-(?<lang>.*)/)

    const CopyButton = props => {
        const [text, setText] = useState("Copy")

        return (
          <button
            tw="self-center"
            className="code-copy-button"
            onClick={() => {
              navigator.clipboard.writeText(props.content)
              setText('Copied!')
              setTimeout(() => {
                setText('Copy')
              }, 3000)
            }}
          >
            {text}
          </button>
        )
    }

    return (
      <div
        className="gatsby-highlight shadow-sm"
        css={{
          borderRadius: '4px 4px 0 0',
          boxShadow: 'inset 0 0 0 1px var(--bg-secondary)',
        }}
      >
        <div
          className="code-header"
          tw="flex flex-row items-stretch"
          css={{
            content: '"⌨️"',
            color: 'var(--textPrimary)',
            padding: '1rem',
            fontSize: '1rem',
            fontFamily: 'monospace',
            textAlign: 'center',
            width: '100%',
            height: 40,
            backgroundColor: 'var(--bg-lighter)',
            boxShadow: 'inset 0 0 0 1px var(--bg-secondary)',
            backgroundImage: 'linear-gradient(45deg, var(--bg-secondary), var(--bg-lighter))',
            borderRadius: '4px 4px 0 0',
          }}
        >
          <span tw="self-center" className="language-name">
            {language}
          </span>
          <CopyButton content={props.children.props.children} />
        </div>
        <Highlight
          {...defaultProps}
          code={props.children.props.children.trim()}
          language={matches && matches.groups && matches.groups.lang ? matches.groups.lang : ''}
          theme=""
        >
          {({ className, tokens, getLineProps, getTokenProps }) => (
            <pre tw="shadow-none" className={className}>
              <code className={className}>
                {tokens.map((line, i) => (
                  <div className="code-block" key={i} {...getLineProps({ line, key: i })}>
                    <span className="line-number">{i + 1}</span>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                ))}
              </code>
            </pre>
          )}
        </Highlight>
      </div>
    )
}
