import React from 'react'
import Helmet from 'react-helmet'
import { format, formatDistance, differenceInDays } from 'date-fns'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import CodeBlock from '../components/CodeBlock'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../containers/layout'
import tw, { theme, css } from 'twin.macro'
import Tilt from 'react-parallax-tilt'
import Grab from '../assets/grab.svg'

const shortcodes = { Link, pre: CodeBlock }

export default function PageTemplate({ data: { mdx }, pageContext, data }) {
  const schemaOrgArticle = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: mdx.frontmatter.title,
    date: mdx.frontmatter.date,
    dateModified: mdx.frontmatter.dateModified,
  }

  const site = data.site
  const siteTitle = site.siteMetadata.title
  const domain = site.siteMetadata.siteUrl

  return (
    <Layout>
      <main
        tw="font-serif"
        css={{
          '& > div:first-of-type': {
            backgroundColor: `${theme`borderColor.accent`}`,
            width: '100%',
            height: 'max(350px, 50vmin)',
            overflow: 'hidden',
          },
        }}
      >
        <Helmet
          titleTemplate="%s"
          htmlAttributes={{
            lang: 'en',
          }}
          title={`${mdx.frontmatter.title} · ${siteTitle}`}
        >
          <meta name="description" content={mdx.frontmatter.description} />
          <meta
            name="image"
            content={`${domain}${mdx.frontmatter.image.childImageSharp.fluid.src}`}
          />

          <meta name="twitter:title" content={`${mdx.frontmatter.title} · ${siteTitle}`} />
          <meta name="twitter:description" content={mdx.frontmatter.description} />
          <meta
            property="twitter:image"
            content={`${domain}${mdx.frontmatter.image.childImageSharp.fluid.src}`}
          />
          <meta property="twitter:card" content="summary" />

          <meta property="og:title" content={`${mdx.frontmatter.title} · ${siteTitle}`} />
          <meta property="og:description" content={mdx.frontmatter.description} />
          <meta
            property="og:image"
            content={`${domain}${mdx.frontmatter.image.childImageSharp.fluid.src}`}
          />
          <meta property="og:type" content="article" />
          <script type="application/ld+json">{JSON.stringify(schemaOrgArticle)}</script>
        </Helmet>
        <div
          tw="pt-10 md:pt-36"
          css={{
            gridColumn: '1/-1',
          }}
        >
          <div
            tw="overflow-hidden"
            css={{
              width: 'min(110ch, 100%)',
              margin: '0 auto',
            }}
          >
            <div
              tw="flex flex-col gap-y-2"
              css={{
                width: 'min(100ch, 100% - 48px)',
                margin: '0 auto',
              }}
            >
              <h1 tw="text-4xl lg:text-5xl md:text-center font-serif font-semibold">
                {mdx.frontmatter.title}
              </h1>
              <div
                tw="md:text-center text-xs font-sans"
                css={{
                  color: `${theme`textColor.softer`}`,
                }}
              >
                Last updated on{' '}
                {format(new Date(`${mdx.frontmatter.dateModified}`), 'do MMMM yyyy')}
              </div>
              {mdx.frontmatter.tags === 'Grab' ? (
                <>
                  <Grab tw="mb-4 md:mb-8 md:mx-auto" css={{ width: 40, height: 32 }} />
                </>
              ) : (
                <div tw="mb-4 md:mb-8"></div>
              )}
              <div tw="col-start-3">
                <Tilt tiltReverse={true} tiltMaxAngleX="5.0" tiltMaxAngleY="5.0" gyroscope={true}>
                  <Img
                    tw="-mb-20 border-none"
                    fluid={mdx.frontmatter.image.childImageSharp.fluid}
                    alt={mdx.frontmatter.title}
                    title={mdx.frontmatter.title}
                    css={{
                      margin: '0 auto',
                    }}
                  />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
        <article
          tw="font-serif prose prose-lg xl:prose-xl max-w-none pt-5 md:pt-16"
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr min(650px, calc(100% - 48px)) 1fr',
            '& > *': {
              gridColumn: 2,
            },
            '.gatsby-highlight': {
              gridColumn: '1/-1',
              backgroundColor: `${theme`backgroundColor.secondary`}`,
              maxWidth: 'min(1024px, calc(100% - 48px))',
              margin: '0 auto',
              '& pre': {
                borderRadius: 0,
                backgroundColor: 'transparent',
                margin: '0 auto',
                maxWidth: 'min(1024px, calc(100% - 48px))',
                paddingLeft: 0,
                paddingRight: 0,
                boxShadow: 'none',
                '& code': {
                  color: `${theme`textColor.primary`}`,
                },
              },
            },
            '@media (min-width: 1024px)': {
              '& > aside': {
                gridColumn: 3,
                padding: '1.75rem',
                paddingTop: '0.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                maxWidth: '400px',
              },
            },
          }}
        >
          <MDXProvider components={shortcodes}>
            <MDXRenderer title={mdx.frontmatter.title}>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </article>
      </main>
      <div
        css={{
          width: 'min(1024px, calc(100% - 48px))',
        }}
        tw="prose text-base grid gap-4 grid-cols-1 md:grid-cols-2 max-w-full mx-auto mt-10 md:mt-16 lg:mt-20 xl:mt-32"
      >
        <div>
          {pageContext.previous && (
            <div>
              <h5 tw="text-gray-500 font-serif mt-0">
                <span tw="font-sans">← </span>Older workplace
              </h5>
              <Link tw="font-serif" to={pageContext.previous.fields.slug}>
                {pageContext.previous.frontmatter.title}
              </Link>
            </div>
          )}
        </div>
        <div>
          {pageContext.next && (
            <div tw="md:text-right">
              <h5 tw="text-gray-500 font-serif mt-0">
                Newer workplace<span tw="font-sans"> →</span>
              </h5>
              <Link tw="font-serif" to={pageContext.next.fields.slug}>
                {pageContext.next.frontmatter.title}
              </Link>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query WorkPostQuery($id: String) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        template
        title
        description
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date
        dateModified
        tags
      }
    }
  }
`
